// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-services-branding-design-js": () => import("./../../../src/pages/services/branding-design.js" /* webpackChunkName: "component---src-pages-services-branding-design-js" */),
  "component---src-pages-services-digital-marketing-digital-advertising-js": () => import("./../../../src/pages/services/digital-marketing/digital-advertising.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-digital-advertising-js" */),
  "component---src-pages-services-digital-marketing-index-js": () => import("./../../../src/pages/services/digital-marketing/index.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-index-js" */),
  "component---src-pages-services-digital-marketing-search-engine-optimization-js": () => import("./../../../src/pages/services/digital-marketing/search-engine-optimization.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-search-engine-optimization-js" */),
  "component---src-pages-services-digital-marketing-social-media-marketing-js": () => import("./../../../src/pages/services/digital-marketing/social-media-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-social-media-marketing-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-web-app-development-js": () => import("./../../../src/pages/services/web-app-development.js" /* webpackChunkName: "component---src-pages-services-web-app-development-js" */),
  "component---src-pages-services-website-design-js": () => import("./../../../src/pages/services/website-design.js" /* webpackChunkName: "component---src-pages-services-website-design-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

